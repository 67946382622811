import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AccessControl,
  useIsAllowed,
} from "../../SharedModule/components/AccessControl";
import {
  actions,
  MoreActionsButton,
} from "../components/InvoicesPage/MoreActionsButton";
import { ChangeOwnerPayload, useChangeOwner } from "../hooks/useChangeOwner";
import { useModal } from "../../SharedModule/hooks/useModal";
import { useUpdateInvoices } from "../hooks/useUpdateInvoices";
import { selectFiltersLabels } from "../redux/reducers/filters.reducer";
import {
  selectFilters,
  selectInvoiceFiltersLabels,
  selectSelectedState,
  selectSummary,
  toggleItemSelection,
  toggleSelectAll,
  resetFilters,
  resetFiltersWithoutOwner,
} from "../redux/reducers/invoices.reducer";
import { selectUsers } from "../redux/reducers/meta.reducer";
import { Invoice } from "../../types";
import {
  ROLE_BILLING_MODULE_EXPORT_INVOICES,
  ROLE_NOT_OWNERS,
  ROLE_SUPER_ADMIN,
  ROUTE_INVOICES_LIST,
} from "../../SharedModule/utils/constants";
import {
  currencyFormat,
  dateFormat,
  dateFormatLocal,
  dateFormatLocalToSort,
} from "../../SharedModule/utils/formatters";
import { InvoicesTable } from "../components/InvoicesPage/InvoicesTable";
import { useSendReminder } from "../hooks/useSendReminder";
import { useInvoices } from "../hooks/useInvoices";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";
import { BillingService } from "../services/billing";
import { exportToExcel } from "../../SharedModule/utils/exportToExcel";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import image from "./../../styles/legacy/404.png";
import { NoContent } from "../components/NoContent";
import { setActiveTab } from "../../ProductsModule/redux/reducers/ui.reducer";
import Footer from "../../SharedModule/components/Footer";

const projectsBaseUrl = process.env.REACT_APP_ASUITE_PROJECT_URI;
const workOrderBaseUrl = process.env.REACT_APP_ASUITE_WO_URI;

export const Invoices = () => {
  const { disabled } = useSelector(selectSelectedState);
  const filtersLabels = useSelector(selectFiltersLabels);
  const filtersState: any = useSelector(selectFilters);
  const invoiceFiltersLabels: any = useSelector(selectInvoiceFiltersLabels);
  const dispatch = useDispatch();
  const summary = useSelector(selectSummary);
  const users = useSelector(selectUsers);
  const { userRoles } = useSelector(selectUserRoles);

  const navigate = useNavigate();
  const location: any = useLocation();

  let invoicesView: string;
  if (location.state && location.state.from === "/billing/filters") {
    invoicesView = "fromFilters";
  } else if (location.state && location.state.from === "/billing/search") {
    invoicesView = "fromSearch";
  } else if (location.state && location.state.from === "/billing/results") {
    invoicesView = "fromResults";
  } else {
    invoicesView = "defaultView";
  }

  const isDefaultView = () => {
    return invoicesView === "defaultView";
  };
  const isSearchView = () => {
    return invoicesView === "fromSearch";
  };
  const isFiltersView = () => {
    return invoicesView === "fromFilters";
  };
  const isResultsView = () => {
    return invoicesView === "fromResults";
  };

  const { isAllowed } = useIsAllowed(ROLE_NOT_OWNERS);
  const { trackPageView } = useMatomo();

  const { idsInputValue } = filtersState;

  const { showModal, closeModal, Modal, ...modalProps } = useModal();

  const {
    invoices,
    allSelected,
    loadInvoices,
    isLoadingInvoices,
    getFiltersData,
  } = useInvoices(true);

  const getBillingGroupUrl = (invoice: Invoice) => {
    return invoice.billingGroup.type.id === 1
      ? `${projectsBaseUrl}${invoice.billingGroup.externalId}`
      : `${workOrderBaseUrl}${invoice.billingGroup.externalId}`;
  };

  const changeOwner = useChangeOwner(showModal, closeModal, loadInvoices);

  const updateInvoicesFn = useUpdateInvoices(showModal, closeModal, invoices);

  const updateInvoices = (action: actions, id?: number): Promise<void> => {
    return updateInvoicesFn(loadInvoices, action, id);
  };

  const sendReminders = useSendReminder(
    showModal,
    closeModal,
    loadInvoices,
    invoices
  );

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
    dispatch(setActiveTab(ROUTE_INVOICES_LIST));
  }, []);

  const getEditQueryLink = () => {
    return location.state && location.state.from === "/billing/filters"
      ? "/billing/filters"
      : "/billing/search";
  };

  const backDefaultInvoices = () => {
    // TO DO: should reset all filters except owners when role dont isAllowed
    if (isAllowed) {
      dispatch(resetFilters());
    } else {
      dispatch(resetFiltersWithoutOwner());
    }
    navigate(ROUTE_INVOICES_LIST);
  };

  const transformData = (invoices: Invoice[]) => {
    let response = invoices.map((invoice: any) => {
      return {
        "Invoice Id": invoice.id,
        "QuickBooks Id": invoice.quickBooksNumber,
        "Invoice Date": dateFormat(invoice.date),
        Client: invoice.account.name,
        "Project/WO": `${invoice.billingGroup.type.name} - #${invoice.billingGroup.externalId} - ${invoice.billingGroup.name}`,
        Owner: invoice.owner.fullName,
        Amount: invoice.amount,
        Status: invoice.status.name,
        "Status Change Date": dateFormatLocalToSort(invoice.status.updatedAt),
      };
    });
    return response;
  };

  let multipleBillingRole: any = [];
  let isSuperAdmin: boolean = false;
  userRoles?.forEach((role) => {
    if (role.module.name === "Billing") {
      multipleBillingRole.push(role);
    }
    if (role.name === ROLE_SUPER_ADMIN) {
      isSuperAdmin = true;
    }
  });

  const exportPDF = async () => {
    let fileName = "The Hub Invoices";
    let data = getFiltersData();
    const invoices: Invoice[] = await BillingService.exportInvoices(data);
    exportToExcel(transformData(invoices), fileName);
  };

  return (
    <>
      <Modal closeModal={closeModal} {...modalProps} />
      <div className="content">
        <div className="content-header w-100 mb-5" style={{ marginTop: "0" }}>
          {(isFiltersView() || isDefaultView()) && (
            <h2 className="flex-fill">Invoices</h2>
          )}
          {isSearchView() && (
            <div className="flex-fill">
              <h2 style={{ marginRight: "2%", display: "inline-flex" }}>Invoices:</h2>
              <CheckSkeleton
                isLoading={isLoadingInvoices}
                classes="w-25 d-inline-flex"
                style={{ lineHeight: "1.8" }}
              >
                <h2 style={{display: "inline-flex" }}>
                  Search result for "{idsInputValue}"
                </h2>
              </CheckSkeleton>
            </div>
          )}
          {isResultsView() && (
            <div className="flex-fill">
              <h2 style={{ marginRight: "2%", display: "inline-flex" }}>Generated Invoices:</h2>
              <CheckSkeleton
                isLoading={isLoadingInvoices}
                classes="w-25 d-inline-flex"
                style={{ lineHeight: "1.8" }}
              >
                <h2 style={{display: "inline-flex" }}>({invoices?.length})</h2>
              </CheckSkeleton>
            </div>
          )}
          {!isDefaultView() && (
            <button
              className="btn button-secondary me-3"
              onClick={() => backDefaultInvoices()}
            >
              {isSearchView() ? "Clear Search" : "Clear Filters"}
            </button>
          )}
          {!isSearchView() && !isResultsView() && (
            <Link to="/billing/filters" className="btn button-secondary me-3">
              {isDefaultView() ? "Filter" : "Edit Filter"}
            </Link>
          )}
          {!isFiltersView() && !isResultsView() && (
            <Link to="/billing/search" className="btn button-secondary">
              {isDefaultView() ? "Search ID" : "Edit Search"}
            </Link>
          )}
        </div>

        {(isDefaultView() || isFiltersView()) && (
          <div className="row my-3 ">
            <div className="col">
              <div className="row lh-lg">
                <div className="col-lg-3 col-12">Invoice Date</div>
                <CheckSkeleton isLoading={isLoadingInvoices} classes="col-9">
                  <div className="col-9 fw-bold">
                    {`${dateFormatLocal(
                      filtersState.dateFrom
                    )} - ${dateFormatLocal(filtersState.dateTo)}`}
                  </div>
                </CheckSkeleton>
              </div>
              <div className="row lh-lg">
                <div className="col-lg-3 col-12">Invoice Status</div>
                <CheckSkeleton isLoading={isLoadingInvoices} classes="col-9">
                  <div className="col-9 fw-bold">
                    {idsInputValue
                      ? "All Statuses"
                      : invoiceFiltersLabels.statuses}
                  </div>
                </CheckSkeleton>
              </div>
            </div>
            <div className="col">
              <div className="row lh-lg">
                <div className="col-lg-3 col-12">Clients</div>
                <CheckSkeleton isLoading={isLoadingInvoices} classes="col-9">
                  <div className="col-9 fw-bold">
                    {idsInputValue
                      ? filtersLabels.clients
                      : invoiceFiltersLabels.clients}
                  </div>
                </CheckSkeleton>
              </div>
              <div className="row lh-lg">
                <div className="col-lg-3 col-12">Owners</div>
                <CheckSkeleton isLoading={isLoadingInvoices} classes="col-9">
                  <div className="col-9 fw-bold">
                    {idsInputValue
                      ? filtersLabels.invoiceOwners
                      : invoiceFiltersLabels.invoiceOwners}
                  </div>
                </CheckSkeleton>
              </div>
            </div>
          </div>
        )}

        {isLoadingInvoices || (invoices && invoices.length > 0) ? (
          <>
            <hr className="header-separator" />
            <div className="pt-2">
              <div className="card d-flex flex-row flex-wrap mt-3 invoice-summary-background">
                <div className="p-4 flex-fill border-end">
                  <div>Total Billable Amount</div>
                  <CheckSkeleton
                    isLoading={isLoadingInvoices}
                    classes="fs-4 pb-1"
                  >
                    <div className="fs-4 fw-bold pb-1">
                      {currencyFormat(summary.total)}
                    </div>
                  </CheckSkeleton>
                  <CheckSkeleton isLoading={isLoadingInvoices}>
                    <div>{summary.count} invoices</div>
                  </CheckSkeleton>
                </div>
                <div className="p-4 flex-fill border-end">
                  <div>Amount Pending</div>
                  <CheckSkeleton
                    isLoading={isLoadingInvoices}
                    classes="fs-4 pb-1"
                  >
                    <div className="fs-4 fw-bold pb-1">
                      {currencyFormat(summary.pendingTotal)}
                    </div>
                  </CheckSkeleton>
                  <CheckSkeleton isLoading={isLoadingInvoices}>
                    <div>{summary.pendingCount} invoices</div>
                  </CheckSkeleton>
                </div>
                <div className="p-4 flex-fill border-end">
                  <div>Amount Invoiced</div>
                  <CheckSkeleton
                    isLoading={isLoadingInvoices}
                    classes="fs-4 pb-1"
                  >
                    <div className="fs-4 fw-bold pb-1">
                      {currencyFormat(summary.invoicedTotal)}
                    </div>
                  </CheckSkeleton>
                  <CheckSkeleton isLoading={isLoadingInvoices}>
                    <div>{summary.invoicedCount} invoices</div>
                  </CheckSkeleton>
                </div>
                <div className="p-4 flex-fill">
                  <div>Amount Voided</div>
                  <CheckSkeleton
                    isLoading={isLoadingInvoices}
                    classes="fs-4 pb-1"
                  >
                    <div className="fs-4 fw-bold pb-1">
                      {currencyFormat(summary.voidedTotal)}
                    </div>
                  </CheckSkeleton>
                  <CheckSkeleton isLoading={isLoadingInvoices}>
                    <div>{summary.voidedCount} invoices</div>
                  </CheckSkeleton>
                </div>
              </div>

              <AccessControl
                allowedRoles={ROLE_NOT_OWNERS}
                fallback={<div style={{ height: "48px" }}></div>}
              >
                <div className="mt-5 pb-3">
                  <button
                    className={`btn btn-primary me-2 ${
                      disabled.approve && "disabled"
                    }`}
                    onClick={() => updateInvoices(actions.approve)}
                  >
                    Approve Invoices
                  </button>
                  <MoreActionsButton
                    disabled={disabled}
                    resetInvoices={() => updateInvoices(actions.reset)}
                    voidInvoices={() => {
                      updateInvoices(actions.void);
                    }}
                    sendReminders={sendReminders}
                  />
                  <button
                    className="btn btn-primary ms-2"
                    onClick={() => exportPDF()}
                  >
                    Export Invoices
                  </button>
                </div>
              </AccessControl>
              {!isSuperAdmin && multipleBillingRole.length === 1 && (
                <AccessControl
                  allowedRoles={ROLE_BILLING_MODULE_EXPORT_INVOICES}
                >
                  <div className="pb-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => exportPDF()}
                    >
                      Export Invoices
                    </button>
                  </div>
                </AccessControl>
              )}
            </div>

            {(isLoadingInvoices || (invoices && invoices.length > 0)) && (
              <InvoicesTable
                isLoading={isLoadingInvoices}
                invoices={invoices}
                allSelected={allSelected}
                selectAll={() => dispatch(toggleSelectAll())}
                summary={summary}
                getBillingGroupUrl={getBillingGroupUrl}
                approveInvoices={(payload: any) =>
                  updateInvoices(actions.approve, payload)
                }
                resetInvoices={(payload: any) =>
                  updateInvoices(actions.reset, payload)
                }
                voidInvoices={(payload: any) =>
                  updateInvoices(actions.void, payload)
                }
                changeOwner={(payload: ChangeOwnerPayload) =>
                  changeOwner(payload)
                }
                toggleItemSelection={(id: any) =>
                  dispatch(toggleItemSelection(id))
                }
                sendReminders={(id: string) => sendReminders(id)}
                users={users}
              />
            )}
          </>
        ) : (
          <div className="container-fluid border-top">
            <NoContent
              title="No Results Found"
              text="Try adjusting your query to find what you’re looking for."
              action={() => navigate(getEditQueryLink())}
              image={image}
              conditionButton={true}
              textButton="Edit Query"
            />
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
